import React, { useState } from "react";
import Cdiscount from "../../assets/logos/custom/cdiscount.png";
import Conforama from "../../assets/logos/custom/conforama.png";
import Leclerc from "../../assets/logos/custom/E.Leclerc.png";
import Geant from "../../assets/logos/custom/geant-casino.png";
import Gens from "../../assets/logos/custom/gensDeConfiance.png";
import Ikea from "../../assets/logos/custom/Ikea-logo.png";
import Leboncoin from "../../assets/logos/custom/leboncoin.png";
import LeroyMerlin from "../../assets/logos/custom/leroyMerlin.png";
import ElectroDepot from "../../assets/logos/custom/electrodepot.png";
import SoCooc from "../../assets/logos/custom/Logo_socooc.png";
import Bricoman from "../../assets/logos/custom/bricoman.png";
import BricoDepot from "../../assets/logos/custom/bricodepot.png";
import Boulanger from "../../assets/logos/custom/boulanger.svg";
import './index.scss'
import useMediaQuery from "@material-ui/core/useMediaQuery";
const PartnersList = [
    {
        label: "Leboncoin",
        src: Leboncoin,
        style: {
            height: 50,
            width: 'auto',
        }
    },
    {
        label: "Ikea",
        src: Ikea,
        style: {
            width: 'auto',
        }
    },
    {
        label: "Conforama",
        src: Conforama,
        style: {
            height: 35,
            width: 'auto',
        }
    },
    {
        label: "Bricoman",
        src: Bricoman,
        style: {
            height: 35,
            width: 'auto',
        }
    },
    {
        label: "Boulanger",
        src: Boulanger,
        style: {
            height: 27,
            width: 'auto',
        }
    },
    {
        label: "BricoDepot",
        src: BricoDepot,
        style: {
            height: 60,
            width: 'auto',
            maxWidth: 130,
            marginTop: '-15px'
        }
    },
    {
        label: "Cdiscount",
        src: Cdiscount,
        style: {
            height: 50,
            width: 'auto',
            marginTop: '-5px'
        }
    },
    {
        label: "E.Leclerc",
        src: Leclerc,
        style: {
            height: 50,
            width: 'auto',
            marginTop: '-5px'
        }
    },
    {
        label: "Géant Casino",
        src: Geant,
        style: {
            height: 50,
            width: 'auto',
            marginTop: '-5px'
        }
    },
    {
        label: "Gens de Confiance",
        src: Gens,
        style: {
            height: 50,
            width: 'auto',
            marginTop: '-10px'
        }
    },
    {
        label: "Leroy Merlin",
        src: LeroyMerlin,
        style: {
            height: 60,
            width: 'auto',
            marginTop: '-17px'
        }
    },
    {
        label: "Electro Depot",
        src: ElectroDepot,
        style: {
            height: 45,
            width: 'auto',
        }
    },
    {
        label: "SoCoo'c",
        src: SoCooc,
        style: {
            height: 140,
            width: 'auto',
            marginTop: '-50px'
        }
    }
]
// takes in images as props
const LogoSlider = () => {
    const matchesTablet = useMediaQuery('(max-width:768px)');
    const matchesMobile = useMediaQuery('(max-width:560px)');
    const [indexList, setIndexList] = useState([0,1,2]); // create state to keep track of images index, set the default index to 0
    const [indexListTablet, setIndexListTablet] = useState([0,1]); // create state to keep track of images index, set the default index to 0
    const [indexListMobile, setIndexListMobile] = useState([0]); // create state to keep track of images index, set the default index to 0
    setTimeout(() => {
        if (matchesMobile) slideRightMobile()
        else if (matchesTablet) slideRightTablet()
        else slideRight()
    }, 1500)

    const slideRight = () => {
        if(indexList[2] === PartnersList.length) {
            setIndexList([PartnersList.length - 1, PartnersList.length, 0])
        } else if (indexList[1] === PartnersList.length) {
            setIndexList([PartnersList.length, 0, 1])
        } else if  (indexList[0] === PartnersList.length){
            setIndexList([0, 1, 2])
        } else {
            const index1 = indexList[1]
            const index2 = indexList[2]
            const index3 = (indexList[2] + 1) % PartnersList.length
            setIndexList([index1, index2, index3])
        }

    };
    const slideRightTablet = () => {
       if (indexListTablet[1] === PartnersList.length) {
           setIndexListTablet([PartnersList.length, 0])
        } else if  (indexListTablet[0] === PartnersList.length){
           setIndexListTablet([0, 1])
        } else {
            const index1 = indexListTablet[1]
            const index2 = (indexListTablet[1] + 1) % PartnersList.length
            setIndexListTablet([index1, index2])
        }

    };
    const slideRightMobile = () => {
       if (indexListMobile[0]+1 === PartnersList.length)  setIndexListMobile([0])
       else  setIndexListMobile([indexListMobile[0]+1])

    };
    return (
        <div className="container">
            { matchesMobile ? indexListMobile.map((i) => <img src={PartnersList[i].src} alt={PartnersList[i].label} style={PartnersList[i].style} key={i}/>) :
                matchesTablet ? indexListTablet.map((i) => <img src={PartnersList[i].src} alt={PartnersList[i].label} style={PartnersList[i].style} key={i}/>) :
                indexList.map((i) => <img src={PartnersList[i].src} alt={PartnersList[i].label} style={PartnersList[i].style} key={i}/> )
            }
        </div>
    );
};

export default LogoSlider;
