import * as jwt from "jsonwebtoken";
export const DashboardService = {
  getDashboarDriver
};
function getDashboarDriver() {
  const METABASE_SECRET_KEY = process.env.REACT_APP_METABASE_SECRET_KEY;
  const dashboarId = parseInt(process.env.REACT_APP_DASHBOARD, 10);
  const connectedUser = JSON.parse(localStorage.getItem("user"));
  if (connectedUser && connectedUser !== null) {
    const company =
      connectedUser.societe && connectedUser.societe !== null
        ? { soci_t__chauffeur: connectedUser.societe.name }
        : {};

    const payload = {
      resource: { dashboard: dashboarId },
      params: {
        ...company
      }
    };
    console.log("compnay name", payload);
    const token = jwt.sign(payload, METABASE_SECRET_KEY);
    return `${process.env.REACT_APP_METABASE_SITE_URL}embed/dashboard/${token}#bordered=false&titled=false`;
  } else {
    return null;
  }
}
