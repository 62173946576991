import React, { Component } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { authenticationActions } from "../../actions";
import "./style.scss";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      dropDownOpen: false
    };
    this.handleMenuChange = this.handleMenuChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  handleMenuChange = () => {
    this.setState({ show: !this.state.show });
  };
  handleDropdownChange = () => {
    this.setState({ dropDownOpen: !this.state.dropDownOpen });
  };
  render() {
    const connectedUser = JSON.parse(localStorage.getItem("user"));
    return (
      <nav className="navbar navbar-expand-sm navbar-light bg-white">
        <div className="navbar-container">
          <Link className="navbar-brand" to="/Dashboard-chauffeur">
            <img
              alt="box2home"
              src={`${process.env.REACT_APP_PUBLIC_URL}assets/img/box2home_logo_bleu.png`}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={this.handleMenuChange}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            id="navbarSupportedContent"
            className={`collapse navbar-collapse ${
              this.state.show ? "show" : "hidden"
            }`}
          >
            <ul className="navbar-nav ml-auto">
              {connectedUser && connectedUser !== null && (
                <li className="nav-item dropdown">
                  <span
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={this.handleDropdownChange}
                  >
                    {connectedUser.firstname} {connectedUser.lastname} <br></br>
                    {connectedUser.societe &&
                      connectedUser.societe !== null && (
                        <span>{connectedUser.societe.name}</span>
                      )}
                    <i
                      className={`fas ${
                        this.state.dropDownOpen
                          ? "fa-caret-up"
                          : "fa-caret-down"
                      }`}
                    ></i>
                  </span>
                  <div
                    aria-labelledby="navbarDropdown"
                    className={`dropdown-menu ${
                      this.state.dropDownOpen ? "show" : "hidden"
                    }`}
                  >
                    <span
                      className="dropdown-item nav-link "
                      onClick={() => {
                        this.props.dispatch(authenticationActions.logout());
                      }}
                    >
                      Déconnexion
                    </span>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  console.log("ssss", state);
  const { loggingIn } = state.global.authentication;
  return {
    loggingIn
  };
}

const connectedHeader = connect(mapStateToProps)(Header);
export { connectedHeader as Header };
