import React from 'react';

const Card = ({card: {title1, title2, content, imgSrc}}) => {
    return (
        <div className="card card-content">
            <div style={{margin: 'auto'}}>
                <img src={imgSrc} className="card-img-top" alt="..."/>
                <div className="card-body">
                    <h5 className="card-title">{title1}</h5>
                    <h5 className="card-title">{title2}</h5>
                    <p className="card-text">{content}</p>
                </div>
            </div>

        </div>
    )
}

export default Card;
