import React, { Component } from "react";
import { requiredValidation, validEmailRegex } from "../../validations";
import "./style.scss";
import { connect } from "react-redux";
import { authenticationActions } from "../../actions";

import Ilustration from '../../assets/images/Ilustration.svg'
import Key from '../../assets/images/Key.svg'
import User from '../../assets/images/User.svg'

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      errors: {
        fullName: "",
        email: "",
        password: ""
      }
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = event => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;
    if (name === "email") {
      this.setState({ email: event.target.value });
      errors.email = validEmailRegex.test(value) ? "" : "Email non valide!";
    }
    this.setState({ errors, [name]: value }, () => {
      console.log(errors);
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    if (requiredValidation(this.state.errors)) {
      console.info("Valid Form", this.state);
      const { email, password } = this.state;
      if (email && password) {
        const { dispatch } = this.props;
        dispatch(authenticationActions.login(email, password));
      }
    } else {
      console.error("Invalid Form", this.state);
    }
  };

  render() {
    const { loggingIn } = this.props;
    const { email, password } = this.state;
    return (
      <div className="login-page max-media-width">
        <div className="login-page-container">
          <div className="col-sm-12 d-none d-sm-block">
            <img
              alt="login partenaire B2H"
              src={Ilustration}
            />
          </div>

          <div className="col-sm-12">
            <form className="grey-form login-form" onSubmit={this.handleSubmit}>
              <div className="form-group">
                <div className="input-inline">
                  <input
                    placeholder="Adresse email"
                    type="email"
                    className="form-control"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                    required
                  />
                  <img
                    className="input-icon"
                    alt="login email"
                    src={User}
                  />
                </div>
              </div>
              <div className="form-group">
                <div className="input-inline">
                  <input
                    placeholder="Mot de passe"
                    type="password"
                    className="form-control"
                    name="password"
                    value={password}
                    onChange={this.handleChange}
                    required
                  />
                  <img
                    className="input-icon"
                    alt="login password"
                    src={Key}
                  />
                </div>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn b2h-primary-btn btn-block mx-auto"
                >
                  Connexion{" "}
                  {loggingIn && <i className="fas fa-spinner fa-pulse"></i>}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.global.authentication;
  return {
    loggingIn
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export { connectedLoginPage as LoginPage };
