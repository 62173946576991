import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import slide1 from '../../assets/images/original images/Application Box2Home3.svg'
import slide2 from '../../assets/images/original images/Chiffre affaires Box2Home3.svg'
import slide3 from '../../assets/images/original images/Livraison Box2Home3.svg';
import useMediaQuery from "@material-ui/core/useMediaQuery";
const Carousal = ({goToFontain, goToSite}) => {
    const matches = useMediaQuery('(max-width:768px)');
    const style = {width: 400, height: 400}
    return (
        <Carousel
            infiniteLoop useKeyboardArrows autoPlay
            showIndicators={true} showArrows={false} showThumbs={false} showStatus={false}>
            <div >
                <div className="carousel-left">
                    <div className="carousal-left-content">
                        <h3>Profitez d'une application simple</h3>
                        { matches && <div>
                            <img src={slide1} style={{width: '80%'}} alt=''/>
                        </div>}
                        <div>
                            Notre application vous permettra de voir votre
                            <span className="bold-text"> planning</span> et vous donnera de la
                            <span className="bold-text"> visibilité sur votre parcours du lendemain.</span><br/>
                            Lorsque vous êtes en livraison, vous profiterez aussi de l'accompagnement de notre
                            <span className="bold-text"> équipe dédiée</span> pour vous aider en cas de besoin.
                        </div>
                        <div>
                            <button type="button" className="btn btn-light b2h-button" onClick={goToFontain}>Je m'inscris</button>
                        </div>
                    </div>
                </div>
                { !matches &&  <div className="carousel-right"><img src={slide1} style={style} alt=''/></div>}
            </div>
            <div>
                <div className="carousel-left">
                    <div className="carousal-left-content">
                        <h3> Suivez votre chiffre d'affaires </h3>
                        {matches && <div>
                            <img src={slide2} style={{width: '80%'}} alt=''/></div>}
                        <div>
                            En plus de notre application, vous aurez accès à un tableau de bord qui vous permettra d'<span className="bold-text">effectuer le suivi de votre activité</span> et de
                            <span className="bold-text"> votre chiffre d'affaires</span>.
                        </div>
                    </div>

                </div>
                { !matches && <div className="carousel-right">
                    <img src={slide2} style={style} alt=''/>
                </div> }
            </div>
            <div>
                <div className="carousel-left">
                    <div className="carousal-left-content">
                        <h3> Bénéficiez d'un large réseau </h3>
                        {matches && <div>
                            <img src={slide3} style={{width: '80%'}} alt=''/>
                        </div>}
                        <div>
                            Box2Home est présent dans
                            <span className="bold-text"> plus de 30 villes et agglomérations en France</span> dont Paris,
                            Bordeaux, Lille, Lyon, Marseille, Rouen, Toulouse...
                            Nos clients se composent de<span className="bold-text"> nombreuses enseignes</span> de mobilier et de bricolage comme <span className="bold-text"> Ikea, Conforama, Leroy Merlin</span> ou encore
                            <span className="bold-text"> Bricoman</span>.
                        </div>
                        <button type="button" className="btn btn-light b2h-button" onClick={goToSite}>En savoir plus</button>
                    </div>
                </div>
                { !matches && <div className="carousel-right">
                    <img src={slide3} style={style} alt=''/>
                </div> }
            </div>
        </Carousel>
    );
}

export default Carousal;
