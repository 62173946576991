import React from 'react';
import './index.scss'
import { Helmet } from 'react-helmet'

import logoB2h from '../../assets/images/logo-b2h.png'
import section6bg from '../../assets/images/original images/Chauffeur livreur indépendant France Box2Home2.svg'
import section1bg2 from '../../assets/images/original images/Chauffeur-livreur2.png'
import section1bg from '../../assets/images/original images/Chauffeur-livreur.png'
import Calendar from '../../assets/images/original images/Planning Box2Home2.svg'
import Wallet from '../../assets/images/original images/CA Box2Home2.svg'
import Shipping from '../../assets/images/original images/Camion Box2Home2.svg'
import EuropCar from '../../assets/logos/Europcar.png'
import Axa from '../../assets/logos/AXA.png'
import Digimoov from '../../assets/logos/digimoov.png'
import checklistImg from './../../assets/images/Checklist-amico.png'
import mention from '../../assets/images/mention.svg'
import Card from './card';
import Carousal from './carousal';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import LogoSlider from "./LogoSlider";

const SITE_PARTENAIRE_URl = "https://www.box2home.fr/nos-partenaires";
const SITE_URl = "https://www.box2home.fr/";
const TYPEFORM_URL = "https://b2h.typeform.com/to/We8S8n";
const DIGIMOOV_URL = "https://www.digimoov.fr/box2home";
const CardList = [
    {
        title1: 'Gérez',
        title2: 'votre temps',
        content: 'Créez votre planning en fonction de vos disponibilités et de vos besoins.',
        imgSrc: Calendar
    },
    {
        title1: "Maximisez votre",
        title2: " chiffre d'affaires",
        content: "Profitez de notre dispatch intelligent qui optimise les livraisons et vous garantit des volumes. En plus, diversifiez votre activité en ajoutant des prestations d'installation ou de montage.",
        imgSrc: Wallet
    },
    {
        title1: "Profitez ",
        title2: "d'avantages",
        content: "Bénéficiez de tarifs négociés pour vous chez nos partenaires loueurs et assureurs.",
        imgSrc: Shipping
    },
]
const checklist = [
    "Un ou plusieurs VL ou un vélo/vélo électrique",

    "Un smartphone",

    "Avoir le droit de créer une entreprise en France",

    "Avoir une assurance de transport de marchandises ",
    "(ou Responsabilité Civile Professionnelle pour les vélos)",

    "Avoir une capacité de transport de marchandises",
    " (pour les véhicules légers)"
]
const SignUpPage = () => {
    const matchesPictoS1 = useMediaQuery('(max-width:1600px)');
    const matches = useMediaQuery('(max-width:1262px)');
    const matches2 = useMediaQuery('(max-width:425px)');
    const gotToTypeForm = () => {
        window.open(TYPEFORM_URL, '_blank');
    }
    const goToSite = () => {
        window.open(SITE_PARTENAIRE_URl, '_blank');
    }
    const goToHomePage = () => {
        window.open(SITE_URl, '_self');
    }
    const toDigimoov = () => {
        window.open(DIGIMOOV_URL, '_blank');
    }
    return (
        <React.Fragment>
            <Helmet
                titleTemplate="Livrez avec Box2Home - S’inscrire"
                defaultTitle="Livrez avec Box2Home - S’inscrire"
            />
        <div className="signup-container">
            <div className="signup-header">
                <div className="logo-header">
                    <img src={logoB2h} alt="logo-b2h" onClick={() => goToHomePage()}/>
                </div>
            </div>
            <div className="sections-container">
                <div className="section-1">
                    <div className="left-section">
                        <div className="left-section-content">
                            <div style={matches2 ? { margin: '0px 10px'} : matches ? { margin: '0px 6vw'} : {maxWidth: 445, margin: '6vw auto'}}>
                                <div>
                                    <h2>Livrez avec Box2Home</h2>
                                </div>
                                <div>
                                    <p className="left-section-subtitle">
                                        Devenez chauffeur livreur indépendant Box2Home et augmentez votre chiffre d'affaires en développant votre activité de transport de marchandises !                                    </p>
                                </div>
                                <div>
                                    { matches && <div>
                                        <img src={matchesPictoS1 ? section1bg2 : section1bg} alt="b2h-worker" style={{maxWidth: 550, width: '100%'}} />
                                    </div>    }
                                    <button type="button" className="btn btn-light b2h-button" onClick={gotToTypeForm}>Je m'inscris</button>
                                    <p className="left-section-subsubtitle">Préparez vos documents pour vous inscrire rapidement en ligne.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    { !matches && <div className="right-section">
                        <img src={section1bg} alt="b2h-worker"/>
                    </div>}
                </div>
                <div className="section-2">
                    <div className="card-container">
                        {CardList.map((card, index) => <Card card={card} key={`card_${index}`}/>)}
                    </div>
                    <div className="sub-section-2">
                        <div className="card-section2" style={matches ? {height: 660} : {}}>

                            { !matches && <div className="left-sub-section2">
                                <img src={checklistImg} alt=''/>
                            </div> }
                            <div className="right-sub-section2">
                                <div className="checklist-title"  style={matches ? {textAlign: 'center'} : {}}>Ce dont vous avez besoin</div>
                                { matches && <img src={checklistImg} alt=''/>}
                                <div className="checklist-content"  style={matches ? {margin: 'auto'} : {}}>
                                    <ul>
                                        {checklist.map((elem, index) => <li key={`checklist_${index}`} style={index === 4 || index === 6 ? { listStyleType: 'none'} : {}}>{elem}</li>)}
                                    </ul>

                                </div>
                                <div className="sub-section-2-button">
                                    <button type="button" className="btn btn-light b2h-button" onClick={gotToTypeForm}>Je m'inscris</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-3">
                    <span>Livrez avec Box2Home </span>
                </div>
                <div className="section-4">
                    <Carousal goToFontain={gotToTypeForm} goToSite={goToSite}/>
                </div>
                <div className="section-5">
                    <div>
                        <img src={EuropCar} alt="europ-car" className="europ-car" style={{width: 130, height: 64}}/>
                        <img src={Axa}  alt="axa" style={{width: 65, height: 64}}/>
                        <img src={Digimoov}  alt="digimoov" style={{width: 65, height: 64,backgroundColor: 'white', padding: 2,
                             cursor: "pointer"}} onClick={() => toDigimoov()}/>
                    </div>

                </div>
                <div className="section-6">
                    { !matches && <div className="section-6-left">
                        <img src={section6bg} alt="b2h-worker-"/>
                    </div>}
                    <div className="section-6-right">

                        <div className="section-6-right-content">
                            <img src={mention} alt="quote-b2h-worker" className="quote-img" style={ matches ? {float: "left"} : {}}/>
                            <div className="quote-text">
                                Je suis chauffeur-livreur avec Box2Home depuis juin 2019.
                                J'ai eu la chance d'ouvrir la ville de Marseille.
                                J'ai multiplié mon CA par 3 et j'ai ouvert ma propre SASU :
                                j'ai maintenant 3 camions qui tournent.
                            </div>
                            { matches &&
                            <img src={section6bg} alt="b2h-worker-" style={{width: '80%'}}/>}
                            <div className="quote-author">
                                — Dylan, chauffeur livreur indépendant Box2Home.
                            </div>
                            <button type="button" className="btn btn-light b2h-button" onClick={gotToTypeForm}>Je m'inscris</button>
                            <p>Préparez vos documents pour vous inscrire rapidement en ligne.</p>
                        </div>
                    </div>
                </div>
                <div className="section-7">
                    <span >
                        Nos Clients
                    </span>
                   {/* <Partners />*/}
                   <LogoSlider />

                </div>
                <div className="section-footer">
                    <div className=" footer-content ">
                        © Box2Home 2016 - 2021, Inc. All Rights Reserved.
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default SignUpPage;
